<template>
    <div
        v-if="$route.name === 'invoice.share-preview'"
        class="flex items-center justify-between bg-white px-12 py-5 shadow-sm"
    >
        <span class="text-sm text-gray-600">{{
            $t('shareLink.preview.title')
        }}</span>

        <base-button outline variant="default" @click="closeTab">{{
            $t('shareLink.preview.backBtn')
        }}</base-button>
    </div>
    <section class="mx-auto min-w-49 max-w-sm px-3 pb-5 pt-24">
        <div class="mb-3 flex justify-between border-b pb-5" v-if="invoice">
            <div>
                <p class="pb-1 text-lg text-gray-900" v-if="company">
                    {{
                        $t('shareLink.recipientText', {
                            no: invoice?.invoice_header,
                            name: company?.company_name,
                        })
                    }}
                </p>
                <span class="text-sm text-gray-500">
                    {{
                        $t('shareLink.infoText', {
                            amount: $filters.currency(
                                invoice.sale_document?.amount_net_total,
                                invoice.sale_document?.currency?.iso
                            ),
                            date: $filters.dateHumanFormat(invoice?.due_date),
                        })
                    }}</span
                >
            </div>

            <div class="flex gap-3 self-start">
                <base-button
                    outline
                    variant="default"
                    :label="$t('general.print')"
                    @click="printDocument(base64PdfContent)"
                />
                <base-button
                    :label="$t('general.download')"
                    @click="onDownload"
                />
            </div>
        </div>
        <base-pdf-viewer :content="base64PdfContent" :loading="isLoadingPdf" />
    </section>
</template>

<script setup>
import { exportPDF, printDocument } from '@tenant/utils/helper'

const { errorNotify } = useNotification()

const route = useRoute()
const base64PdfContent = ref(null)
const invoice = ref(null)
const company = ref(null)
const isLoadingPdf = ref(false)
const { execute } = useApi(
    '/api/sale-documents/invoices/{tenantId}/{hash}/get-pdf-content',
    'POST'
)
onMounted(async () => {
    try {
        isLoadingPdf.value = true

        const result = await execute({
            params: {
                tenantId: route.params.tenantId,
                hash: route.params.hash,
            },
        })

        invoice.value = result.invoice
        company.value = result.company
        base64PdfContent.value = result.content
    } catch ({ errorMessage }) {
        errorNotify({
            title: errorMessage,
        })
    } finally {
        isLoadingPdf.value = false
    }
})

const onDownload = () => {
    exportPDF(`${invoice.value.invoice_header}.pdf`, base64PdfContent.value)
}

const closeTab = () => window.close()
</script>
