<template>
    <base-context-menu>
        <base-context-menu-item
            v-if="status.isPartPaid() || status.isDue()"
            icon="line-icons:finance-&-ecommerce:currency-dollar-circle"
        >
            {{ $t('invoices.list.contextMenu.bookInComing') }}
        </base-context-menu-item>

        <base-context-menu-item
            v-if="!status.isDraft()"
            icon="line-icons:general:download-01"
            @click="$emit('download-invoice')"
        >
            {{ $t('invoices.list.contextMenu.download') }}
        </base-context-menu-item>

        <base-context-menu-item
            v-if="!status.isDraft()"
            icon="line-icons:general:eye"
            @click="onPreviewInvoice"
        >
            {{ $t('invoices.list.contextMenu.preview') }}
        </base-context-menu-item>

        <!-- TODO: AC-545 -->
        <!--        <base-context-menu-item icon="line-icons:general:check">-->
        <!--            {{ $t('invoices.list.contextMenu.addTask') }}-->
        <!--        </base-context-menu-item>-->

        <!-- TODO: AC-546 -->
        <!--        <base-context-menu-item-->
        <!--            v-if="status.isPartPaid() || status.isPaid() || status.isDue()"-->
        <!--            icon="line-icons:maps-&-travel:truck-01"-->
        <!--        >-->
        <!--            {{ $t('invoices.list.contextMenu.addDelivery') }}-->
        <!--        </base-context-menu-item>-->

        <base-context-menu-item
            v-if="$acl.can('create_invoices')"
            icon="line-icons:general:copy-01"
            @click="$emit('copy-invoice')"
        >
            {{ $t('invoices.list.contextMenu.copyInvoice') }}
        </base-context-menu-item>

        <!-- TODO: AC-547 -->
        <!--        <base-context-menu-item-->
        <!--            v-if="status.isPartPaid() || status.isDue()"-->
        <!--            icon="line-icons:time:alarm-clock"-->
        <!--        >-->
        <!--            {{ $t('invoices.list.contextMenu.paymentReminder') }}-->
        <!--        </base-context-menu-item>-->

        <base-context-menu-item
            v-if="status.isDraft() && $acl.can('update_invoices')"
            icon="line-icons:files:file-attachment-04"
            @click="onEditDocument"
        >
            {{ $t('invoices.list.contextMenu.editDocument') }}
        </base-context-menu-item>

        <base-context-menu-item
            v-if="
                (status.isPartPaid() || status.isPaid()) &&
                $acl.can('update_invoices')
            "
            icon="line-icons:arrows:flip-backward"
        >
            {{ $t('invoices.list.contextMenu.markAsUnpaid') }}
        </base-context-menu-item>

        <!-- TODO: AC-548 -->
        <!--        <base-context-menu-item-->
        <!--            v-if="status.isPaid()"-->
        <!--            icon="line-icons:general:info-circle"-->
        <!--        >-->
        <!--            {{ $t('invoices.list.contextMenu.receivedInfo') }}-->
        <!--        </base-context-menu-item>-->

        <!-- TODO: AC-542 -->
        <!--        <base-context-menu-item-->
        <!--            v-if="status.isPartPaid() || status.isDue()"-->
        <!--            icon="line-icons:general:slash-circle-01"-->
        <!--        >-->
        <!--            {{ $t('invoices.list.contextMenu.cancelInvoice') }}-->
        <!--        </base-context-menu-item>-->

        <base-context-menu-item
            v-if="status.isDraft() && $acl.can('delete_invoices')"
            icon="line-icons:general:trash-01"
            @click="onDeleteInvoice"
        >
            {{ $t('general.delete') }}
        </base-context-menu-item>
    </base-context-menu>
</template>

<script setup>
import { createEntityStatus } from '@tenant/utils/entity-status'

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
})

const status = computed(() =>
    createEntityStatus(props.item.sale_document.status)
)
const emit = defineEmits(['delete-invoice', 'download-invoice', 'copy-invoice'])

const router = useRouter()

const onEditDocument = () => {
    router.push({
        name: 'invoices.edit',
        params: { id: props.item.id },
    })
}

const onPreviewInvoice = () => {
    router.push({
        name: 'invoices.dispatch',
        params: { id: props.item.id },
    })
}

const onDeleteInvoice = () => {
    emit('delete-invoice', props.item.id)
}
</script>
