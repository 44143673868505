<template>
    <base-modal close :show="show" @modal-close="closeModal" size="md">
        <template #modal-header>
            <span class="text-lg font-medium text-gray-900">
                {{ $t('invoices.dispatch.statusChangeModal.title') }}
            </span>
        </template>

        <span class="text-sm text-gray-500">
            {{ $t('invoices.dispatch.statusChangeModal.description') }}
        </span>

        <template #modal-footer>
            <div class="flex justify-between gap-3">
                <base-button
                    outline
                    full-width
                    variant="default"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button full-width @click="onSubmit" :loading="loading">
                    {{ $t('general.confirm') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
})
const emit = defineEmits(['modal-close', 'modal-confirm'])

const closeModal = () => {
    emit('modal-close')
}

const onSubmit = () => {
    emit('modal-confirm')
}
</script>
