<template>
    <div class="my-6">
        <base-section-divider :text="$t('createInvoice.customerInfoTitle')" />
        <div class="grid grid-cols-2 items-start gap-x-4">
            <div class="grid grid-cols-1 gap-y-5">
                <form-contact-select
                    v-if="(contactId && contact) || !contactId"
                    v-model="contactId"
                    :label="$t('createInvoice.customerLabel')"
                    :placeholder="$t('createInvoice.customerPlaceholder')"
                    :error-message="errorContactId"
                    :input-display="getContactName(contact)"
                    @on-contact-changed="onContactChange"
                />

                <form-textarea
                    :label="$t('createInvoice.customerAddressLabel')"
                    :placeholder="
                        $t('createInvoice.customerAddressPlaceholder')
                    "
                    rows="6"
                    v-model="customerAddress"
                />

                <form-single-select
                    v-model="customerCountry"
                    :label="$t('createInvoice.customerCountryLabel')"
                    :options="countries"
                    :error-message="errorCustomerCountry"
                />
            </div>
            <div class="grid grid-cols-2 gap-x-3 gap-y-5">
                <slot name="right">
                    <form-text-input
                        :label="$t('createInvoice.invoiceHeaderLabel')"
                        :placeholder="
                            $t('createInvoice.invoiceHeaderPlaceholder')
                        "
                        v-model="invoiceHeader"
                        :error-message="errorInvoiceHeader"
                    />
                    <form-text-input
                        v-model="invoiceNumber"
                        :label="$t('createInvoice.invoiceNumberLabel')"
                        :placeholder="
                            $t('createInvoice.invoiceNumberPlaceholder')
                        "
                        :error-message="errorInvoiceNumber"
                        disabled
                    />
                    <form-date-picker
                        :label="$t('createInvoice.dateOfInvoiceLabel')"
                        single
                        v-model="dateOfInvoice"
                        :error-message="errorDateOfInvoice"
                    />

                    <form-single-select
                        v-model="paymentTerm"
                        :error-message="errorPaymentTerm"
                        :label="$t('createInvoice.paymentTermsLabel')"
                        :options="paymentTerms"
                    />

                    <form-date-picker
                        :label="$t('createInvoice.dueDateOfInvoiceLabel')"
                        single
                        v-model="dueDateOfInvoice"
                        :error-message="errorDueDateOfInvoice"
                        @update:model-value="onDueDateChange"
                    />
                    <form-date-picker
                        :label="$t('createInvoice.invoiceDateOfDeliveryLabel')"
                        single
                        v-model="deliveryDate"
                        :error-message="errorDeliveryDate"
                    />
                    <form-text-input
                        :label="$t('createInvoice.invoiceReferenceNoLabel')"
                        placeholder="CY1245234"
                        v-model="referenceNumber"
                        :error-message="errorReferenceNumber"
                    />
                </slot>
            </div>
        </div>
    </div>
</template>

<script setup>
import { dayjsFormat, now, shortServerFormat } from '@tenant/utils/day'
import {
    DATE_SERVER_FORMAT,
    DEFAULT_COUNTRY_ISO,
    PAYMENT_TERMS,
} from '@tenant/utils/constants'
import { dateFromPaymentTerm, getContactName } from '@tenant/utils/helper'

const { countries } = useCountries()
const route = useRoute()

// FORM VALUES
const { value: customerAddress } = useField('address')
const { value: customerCountry, errorMessage: errorCustomerCountry } =
    useField('country_id')
const { value: currency } = useField('currency_id')
const { value: contactCurrency } = useField('contact_currency')
const { value: exchangeRate } = useField('exchange_rate')
const { value: invoiceHeader, errorMessage: errorInvoiceHeader } =
    useField('invoice_header')
const { value: invoiceNumber, errorMessage: errorInvoiceNumber } =
    useField('number')
const { value: dateOfInvoice, errorMessage: errorDateOfInvoice } =
    useField('date_of_invoice')
const { value: dueDateOfInvoice, errorMessage: errorDueDateOfInvoice } =
    useField('due_date')
const { value: deliveryDate, errorMessage: errorDeliveryDate } =
    useField('date_of_delivery')
const { value: referenceNumber, errorMessage: errorReferenceNumber } =
    useField('reference')
const { value: contactId, errorMessage: errorContactId } =
    useField('contact_id')
const { value: contact } = useField('contact')
const { value: paymentTerm, errorMessage: errorPaymentTerm } =
    useField('payment_term')
// END FORM VALUES

// CONTACTS
const { useApiGet } = useApiFactory('contacts')
const { execute: executeGet, result } = useApiGet()

const emitter = useEmitter()
onMounted(async () => {
    emitter.emit('set-loading', true)

    if (route.query.contact_id) {
        contactId.value = route.query.contact_id
        await executeGet(contactId.value)
        contact.value = result.value
    }

    if (!route?.params?.id) {
        await generateInvoiceNumber()
    }

    emitter.emit('set-loading', false)
})

const generateInvoiceNumber = () => {
    const { execute } = useApi(
        '/api/sequence-settings/generate-number/invoice',
        'GET'
    )

    return execute().then((response) => (invoiceNumber.value = response))
}

/**
 * Set default country to Cyprus
 * @returns {*}
 */
const setDefaultCountry = () => {
    customerCountry.value = countries.value.find(
        (country) => country.iso === DEFAULT_COUNTRY_ISO
    )?.value
}

const isContactChanged = ref(false)
const onContactChange = ({ id, item }) => {
    contactId.value = id
    contact.value = item
    isContactChanged.value = true

    // Set default configuration from contact to invoice
    if (contact.value?.sales_payment_term) {
        paymentTerm.value = contact.value.sales_payment_term
    }
}

watch(
    contact,
    () => {
        currency.value = contact.value?.currency_id
        contactCurrency.value = contact.value?.currency
        if (!exchangeRate.value) {
            exchangeRate.value = {
                date: shortServerFormat(contactCurrency.value?.rate_updated_at),
                rate: contactCurrency.value?.rate,
            }
        }

        if (!isContactChanged.value) return

        if (contact.value?.contact_addresses?.length > 0) {
            const { street, postcode, city, country_id } =
                contact.value.contact_addresses[0]
            if (street && postcode && city) {
                customerAddress.value = `${street}\n${postcode} ${city}`
            }

            if (country_id && customerCountry.value !== country_id) {
                customerCountry.value = country_id
            } else {
                setDefaultCountry()
            }
        } else {
            customerAddress.value = ''
            setDefaultCountry()
        }
    },
    {
        immediate: true,
    }
)
// END CONTACTS

// Auto-generate invoice number
const { t } = useI18n()
watch(invoiceNumber, () => {
    invoiceHeader.value = t('createInvoice.invoiceHeaderPlaceholderValue', {
        number: invoiceNumber.value,
    })
})

// Set `Date of invoice` and `Date of delivery` default by today.
onMounted(() => {
    const today = dayjsFormat(now(), DATE_SERVER_FORMAT)
    if (!dateOfInvoice.value) {
        dateOfInvoice.value = today
    }
    if (!deliveryDate.value) {
        deliveryDate.value = today
    }
    if (!paymentTerm.value) {
        paymentTerm.value = PAYMENT_TERMS.DUE_ON_RECEIPT
    }
})

watch(
    countries,
    () => {
        if (!customerCountry.value) {
            setDefaultCountry()
        }
    },
    { immediate: true }
)

// HANDLE PAYMENT TERMS
const paymentTerms = computed(() => {
    return Object.values(PAYMENT_TERMS).map((item) => ({
        value: item,
        label: t(`general.paymentTerms.${item}`),
    }))
})

watch(
    () => paymentTerm.value,
    () => {
        if (paymentTerm.value === PAYMENT_TERMS.CUSTOM) return

        dueDateOfInvoice.value = dateFromPaymentTerm(
            dateOfInvoice.value,
            paymentTerm.value
        )
    }
)

const onDueDateChange = () => {
    paymentTerm.value = PAYMENT_TERMS.CUSTOM
}
// END: HANDLE PAYMENT TERMS
</script>
