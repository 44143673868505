<template>
    <base-modal close :show="show" @modal-close="closeModal" size="md">
        <template #modal-header>
            <span class="text-lg font-medium text-gray-900">
                {{ $t('invoices.dispatch.markAsSentModal.title') }}
            </span>
        </template>

        <span class="text-sm text-gray-500">
            {{ $t('invoices.dispatch.markAsSentModal.description') }}
        </span>

        <template #modal-footer>
            <div class="flex justify-between gap-3">
                <base-button
                    outline
                    full-width
                    variant="default"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button full-width @click="onSubmit" :loading="loading">
                    {{ $t('invoices.dispatch.markAsSentModal.confirmBtn') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const props = defineProps({
    items: {
        type: Array,
        default: () => [],
    },
    show: {
        type: Boolean,
        default: false,
    },
})
const emit = defineEmits(['modal-close', 'modal-confirm'])

const closeModal = () => {
    emit('modal-close')
}

// MARK AS SENT
const { errorNotify, successNotify } = useNotification()

const { execute: executeMarkAsSent, loading } = useApi(
    '/api/sale-documents/invoices/mark-as-sent',
    'POST'
)

const { t } = useI18n()
const onSubmit = async () => {
    try {
        await executeMarkAsSent({
            data: {
                ids: props.items.map((item) => item.id),
            },
        })

        emit('modal-confirm')

        successNotify({
            text: t('invoices.dispatch.markAsSentModal.successMsg'),
        })
    } catch ({ errorMessage }) {
        errorNotify({
            title: errorMessage,
        })
    }
}
// END: MARK AS SENT
</script>
