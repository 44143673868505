<template>
    <div class="my-6">
        <base-section-divider :text="$t('createInvoice.headerContentTitle')" />
        <form-rich-text-editor
            v-model="headerContent"
            :error-message="errorHeaderContent"
        />
    </div>
</template>

<script setup>
const props = defineProps({
    defaultContent: {
        type: String,
        default: '',
    },
})

const { value: headerContent, errorMessage: errorHeaderContent } =
    useField('header_content')

watch(
    () => props.defaultContent,
    (value) => {
        headerContent.value = value
    },
    { immediate: true }
)
</script>
