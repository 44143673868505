<template>
    <base-modal close :show="show" @modal-close="closeModal" size="lg">
        <template #modal-header>
            <span class="text-lg font-medium text-gray-900">
                {{ $t('invoices.dispatch.sendEmailsModal.title') }}
            </span>
        </template>

        <p class="mb-5 text-sm text-gray-500">
            {{ $t('invoices.dispatch.sendEmailsModal.description') }}
        </p>

        <div class="grid grid-cols-2 gap-2.5">
            <p class="text-sm font-semibold text-gray-700">
                {{ $t('invoices.dispatch.sendEmailsModal.sendToText') }}
            </p>
            <p class="text-sm font-semibold text-gray-700">
                {{ $t('invoices.dispatch.sendEmailsModal.emailText') }}
            </p>

            <template v-for="contact in contacts" :key="contact.id">
                <p class="text-sm text-gray-500">{{ contact.name }}</p>
                <form-checkbox
                    :model-value="
                        selectedContacts.some((item) => contact.id === item.id)
                    "
                    @update:model-value="onContactSelected($event, contact)"
                />
            </template>
        </div>

        <template #modal-footer>
            <div class="flex justify-between gap-3">
                <base-button
                    outline
                    full-width
                    variant="default"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button full-width @click="onSubmit" :loading="loading">
                    {{ $t('invoices.dispatch.sendEmailsModal.sendBtn') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
import { cloneDeep } from 'lodash-es'
const { errorNotify, successNotify } = useNotification()

const props = defineProps({
    contacts: {
        type: Array,
        default: () => [],
    },
    show: {
        type: Boolean,
        default: false,
    },
})
const emit = defineEmits(['modal-close', 'modal-confirm'])

const closeModal = () => {
    emit('modal-close')
}

const { execute, loading } = useApi(
    '/api/sale-documents/invoices/bulk-send',
    'POST'
)

const { t } = useI18n()
const onSubmit = async () => {
    try {
        await execute({
            data: {
                ids: selectedContacts.value.map((item) => item.id),
            },
        })

        emit('modal-confirm')

        successNotify({
            text: t('invoices.dispatch.sendEmailsModal.successMsg'),
        })
    } catch ({ errorMessage }) {
        errorNotify({
            title: errorMessage,
        })
    }
}

const selectedContacts = ref([])
onMounted(() => {
    selectedContacts.value = cloneDeep(props.contacts)
})

const onContactSelected = (value, contact) => {
    if (value) {
        selectedContacts.value.push(contact)
    } else {
        selectedContacts.value = selectedContacts.value.filter(
            (item) => item.id !== contact.id
        )
    }
}
</script>
