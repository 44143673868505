<template>
    <base-button-group>
        <base-button
            variant="default"
            :class="{
                'bg-gray-100': !status,
            }"
            @click="viewChange()"
        >
            {{ $t('invoices.list.viewAll') }}
        </base-button>
        <base-button
            variant="default"
            @click="viewChange(INVOICE_STATUS.DRAFT)"
            :class="{
                'bg-gray-100': hasStatus(INVOICE_STATUS.DRAFT),
            }"
        >
            {{ $t('invoices.list.draft') }}
        </base-button>
        <base-button
            variant="default"
            @click="viewChange(INVOICE_STATUS.UNPAID)"
            :class="{
                'bg-gray-100': hasStatus(INVOICE_STATUS.UNPAID),
            }"
        >
            {{ $t('invoices.list.unpaid') }}
        </base-button>
        <base-button
            variant="default"
            @click="viewChange(INVOICE_STATUS.DUE)"
            :class="{
                'bg-gray-100': hasStatus(INVOICE_STATUS.DUE),
            }"
        >
            {{ $t('invoices.list.due') }}
        </base-button>
        <base-button
            variant="default"
            @click="viewChange(INVOICE_STATUS.PART_PAID)"
            :class="{
                'bg-gray-100': hasStatus(INVOICE_STATUS.PART_PAID),
            }"
        >
            {{ $t('invoices.list.partPaid') }}
        </base-button>
        <base-button
            variant="default"
            @click="viewChange(INVOICE_STATUS.PAID)"
            :class="{
                'bg-gray-100': hasStatus(INVOICE_STATUS.PAID),
            }"
        >
            {{ $t('invoices.list.paid') }}
        </base-button>
        <base-button
            variant="default"
            @click="viewChange(INVOICE_STATUS.REPEATING)"
            :class="{
                'bg-gray-100': hasStatus(INVOICE_STATUS.REPEATING),
            }"
        >
            {{ $t('invoices.list.repeating') }}
        </base-button>
    </base-button-group>
</template>

<script setup>
import { useFilters } from '@tenant/composables'
import { INVOICE_STATUS } from '@tenant/modules/tenant/invoices/utils/constants'
import { dayjsFormat, now } from '@tenant/utils/day'
import { DATE_SERVER_FORMAT } from '@tenant/utils/constants'

const queries = {
    type: 'filter.saleDocument.status',
    due_date: 'filter.due_date',
}
const { filterValues, updateFilters } = useFilters(queries)

const status = ref(null)

onMounted(() => {
    initStatus()
})

const initStatus = () => {
    const { type } = filterValues.value
    if (!type) {
        return
    }

    if (Array.isArray(type) && type.length > 0) {
        const statuses = type[1] || []

        if (
            statuses.includes(INVOICE_STATUS.UNPAID) &&
            statuses.includes(INVOICE_STATUS.PART_PAID)
        ) {
            status.value = INVOICE_STATUS.DUE

            return
        }
    }

    status.value = type
}

const viewChange = (updateStatus = null) => {
    const filters = {
        [queries.type]: updateStatus,
        [queries.due_date]: null,
    }

    if (updateStatus === INVOICE_STATUS.UNPAID) {
        filters[queries.type] = INVOICE_STATUS.UNPAID
        filters[queries.due_date] = [
            'DATE >',
            dayjsFormat(now(), DATE_SERVER_FORMAT),
        ]
    }

    if (updateStatus === INVOICE_STATUS.DUE) {
        filters[queries.type] = [
            'IN',
            [INVOICE_STATUS.UNPAID, INVOICE_STATUS.PART_PAID],
        ]
        filters[queries.due_date] = [
            'DATE <=',
            dayjsFormat(now(), DATE_SERVER_FORMAT),
        ]
    }

    status.value = updateStatus
    updateFilters(filters)
}

const hasStatus = (value) => {
    return status.value === value
}
</script>
