<template>
    <base-compact-list
        :items="invoices"
        :is-loading="isLoadingInvoices"
        :initial-selected-item="invoice"
        selectable
        @item-click="onSidebarItem"
        @load-more="onLoadMore"
        @item-check="onItemCheck"
        :initialize="initializeList"
    >
        <template #header="{ checkedItems }">
            <template v-if="checkedItems.length">
                <div class="flex items-center gap-2.5">
                    <base-button
                        v-if="$acl.can('update_invoices')"
                        variant="default"
                        outline
                        size="sm"
                        class="bg-white"
                        @click="isShowMarkAsSentModal = true"
                    >
                        <span class="font-normal">
                            {{
                                $t(
                                    'invoices.dispatch.sidebarList.markAsSentBtn'
                                )
                            }}
                        </span>
                    </base-button>

                    <base-context-menu
                        v-if="$acl.can('update_invoices')"
                        size="md"
                        position="left"
                        class="rounded-md border border-gray-300 bg-white p-2"
                    >
                        <base-context-menu-item
                            @click="showSendInvoiceModal = true"
                        >
                            {{
                                $t(
                                    'invoices.dispatch.sidebarList.sendEmailsBtn'
                                )
                            }}
                        </base-context-menu-item>
                    </base-context-menu>

                    <span class="text-sm text-gray-700">
                        {{
                            $t(
                                'invoices.dispatch.sidebarList.selectedText',
                                checkedItems.length
                            )
                        }}
                    </span>
                </div>
            </template>

            <template v-else>
                <base-dropdown size="sm" variant="default" class="bg-white">
                    <template #btn-content>
                        {{ statusText }}

                        <base-icon
                            name="line-icons:arrows:chevron-down"
                            variant="inherit"
                        />
                    </template>

                    <template #default="{ toggleDropdown }">
                        <base-dropdown-item
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange()
                                }
                            "
                        >
                            {{
                                $t(
                                    'invoices.dispatch.sidebar.filter.allInvoices'
                                )
                            }}
                        </base-dropdown-item>

                        <base-dropdown-item
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange(INVOICE_STATUS.DRAFT)
                                }
                            "
                        >
                            {{ $t('invoices.list.draft') }}
                        </base-dropdown-item>

                        <base-dropdown-item
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange(INVOICE_STATUS.UNPAID)
                                }
                            "
                        >
                            {{ $t('invoices.list.unpaid') }}
                        </base-dropdown-item>

                        <base-dropdown-item
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange(INVOICE_STATUS.DUE)
                                }
                            "
                        >
                            {{ $t('invoices.list.due') }}
                        </base-dropdown-item>

                        <base-dropdown-item
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange(INVOICE_STATUS.PART_PAID)
                                }
                            "
                        >
                            {{ $t('invoices.list.partPaid') }}
                        </base-dropdown-item>

                        <base-dropdown-item
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange(INVOICE_STATUS.PAID)
                                }
                            "
                        >
                            {{ $t('invoices.list.paid') }}
                        </base-dropdown-item>
                    </template>
                </base-dropdown>

                <base-button
                    v-if="$acl.can('create_invoices')"
                    icon="line-icons:general:plus"
                    :label="$t('general.new')"
                    size="sm"
                    @click="goToInvoiceCreate"
                />
            </template>
        </template>

        <template #item-content="{ item, clickItem }">
            <div class="w-full" @click="clickItem(item)">
                <div class="mb-1 flex w-full items-center justify-between">
                    <p class="text-sm text-gray-700">
                        {{
                            truncateString(
                                getContactName(
                                    item?.sale_document?.contact?.contact
                                ),
                                25
                            )
                        }}
                    </p>

                    <p class="text-sm text-gray-700">
                        {{
                            $filters.currency(
                                item?.sale_document?.amount_gross_total,
                                item?.sale_document?.currency?.iso
                            )
                        }}
                    </p>
                </div>

                <div class="flex w-full items-center justify-between">
                    <p class="text-xs">
                        <span class="text-primary-600">
                            {{ item?.sale_document?.number }}
                        </span>

                        <span class="px-2.5 text-gray-300">|</span>

                        <span class="text-gray-500">{{
                            $filters.dateHumanFormat(item?.date_of_invoice)
                        }}</span>
                    </p>

                    <partial-status
                        :status="
                            getInvoiceStatus(
                                item.sale_document.status,
                                item.due_date
                            )
                        "
                        variant="small"
                    >
                        <template #text>{{ getStatusText(item) }}</template>
                    </partial-status>
                </div>
            </div>
        </template>
    </base-compact-list>

    <invoices-mark-as-sent-modal
        :items="checkedItems"
        :show="isShowMarkAsSentModal"
        @modal-close="isShowMarkAsSentModal = false"
        @modal-confirm="onMarkAsSent"
    />

    <invoices-send-multiple-emails-modal
        v-if="showSendInvoiceModal"
        :show="showSendInvoiceModal"
        :contacts="checkedContacts"
        @modal-close="showSendInvoiceModal = false"
        @modal-confirm="onSendInvoice"
    />
</template>

<script setup>
import { isEqual, uniqBy } from 'lodash-es'
import { useInvoice } from '@tenant/modules/tenant/invoices/composables/use-invoice'
import { useFilters } from '@tenant/composables'
import { INVOICE_STATUS } from '@tenant/modules/tenant/invoices/utils/constants'
import { dayjsFormat, now } from '@tenant/utils/day'
import { DATE_SERVER_FORMAT } from '@tenant/utils/constants'
import { useInfiniteQuery } from '@tanstack/vue-query'
import { getContactName, truncateString } from '@tenant/utils/helper'
const { getInvoiceStatus, getStatusText } = useInvoice()

const props = defineProps({
    invoice: {
        type: Object,
        default: () => null,
    },
})

const route = useRoute()
const router = useRouter()

// INFINITE SCROLL INVOICE LIST
const { useApiSearch } = useApiFactory('sale-documents/invoices')
const { execute } = useApiSearch({}, false)

const {
    data: invoices,
    isFetching: isLoadingInvoices,
    fetchNextPage: fetchMoreAccounts,
    hasNextPage: hasMoreAccounts,
    refetch,
} = useInfiniteQuery({
    queryKey: ['invoices'],
    queryFn: ({ pageParam = 1 }) => {
        return execute({
            page: pageParam,
            limit: 20,
            ...route.query,
        })
    },
    getNextPageParam: ({ current_page, last_page }) => {
        return current_page < last_page ? current_page + 1 : undefined
    },
    select: (res) =>
        uniqBy(
            res?.pages?.flatMap((x) => x?.data),
            (x) => x?.id
        ),
})

const onLoadMore = () => {
    if (hasMoreAccounts.value) {
        fetchMoreAccounts()
    }
}

// END: INFINITE SCROLL INVOICE LIST

const onSidebarItem = (item) => {
    router.push({
        name: 'invoices.dispatch',
        params: { id: item.id },
        query: { ...route.query },
    })
}

const goToInvoiceCreate = () => {
    router.push({ name: 'invoices.create' })
}

// FILTER INVOICES
const queries = {
    type: 'filter.saleDocument.status',
    due_date: 'filter.due_date',
}
const { filterValues, updateFilters } = useFilters(queries)
const status = ref(null)
watch(
    () => filterValues.value,
    (value, oldValue) => {
        if (isEqual(value, oldValue)) return
        refetch()
    }
)
watch(
    () => props.invoice?.sale_document?.status,
    () => {
        refetch()
    }
)

onMounted(() => {
    initStatus()
})

const initStatus = () => {
    const { type } = filterValues.value
    if (!type) {
        return
    }

    if (Array.isArray(type) && type.length > 0) {
        const statuses = type[1] || []

        if (
            statuses.includes(INVOICE_STATUS.UNPAID) &&
            statuses.includes(INVOICE_STATUS.PART_PAID)
        ) {
            status.value = INVOICE_STATUS.DUE

            return
        }
    }

    status.value = type
}

const filterChange = (updateStatus = null) => {
    const filters = {
        [queries.type]: updateStatus,
        [queries.due_date]: null,
    }

    if (updateStatus === INVOICE_STATUS.UNPAID) {
        filters[queries.type] = INVOICE_STATUS.UNPAID
        filters[queries.due_date] = [
            'DATE >',
            dayjsFormat(now(), DATE_SERVER_FORMAT),
        ]
    }

    if (updateStatus === INVOICE_STATUS.DUE) {
        filters[queries.type] = [
            'IN',
            [INVOICE_STATUS.UNPAID, INVOICE_STATUS.PART_PAID],
        ]
        filters[queries.due_date] = [
            'DATE <=',
            dayjsFormat(now(), DATE_SERVER_FORMAT),
        ]
    }

    status.value = updateStatus
    updateFilters(filters)
}

const { t } = useI18n()
const statusText = computed(() => {
    if (status.value === INVOICE_STATUS.DUE) {
        return t('invoices.list.due')
    }

    if (status.value === INVOICE_STATUS.UNPAID) {
        return t('invoices.list.unpaid')
    }

    if (status.value === INVOICE_STATUS.PART_PAID) {
        return t('invoices.list.partPaid')
    }

    if (status.value === INVOICE_STATUS.PAID) {
        return t('invoices.list.paid')
    }

    if (status.value === INVOICE_STATUS.DRAFT) {
        return t('invoices.list.draft')
    }

    return t('invoices.dispatch.sidebar.filter.allInvoices')
})
// END: FILTER INVOICES

const checkedItems = ref([])
const onItemCheck = ({ checkedItems: items }) => {
    checkedItems.value = items
}

const checkedContacts = computed(() => {
    return uniqBy(
        checkedItems.value,
        (item) => item?.sale_document?.contact?.contact_id
    ).map((item) => ({
        name: getContactName(item?.sale_document?.contact),
        id: item.id,
    }))
})

const initializeList = ref(false)

// MARK AS SENT
const emit = defineEmits(['data-update'])

const isShowMarkAsSentModal = ref(false)
const onMarkAsSent = async () => {
    isShowMarkAsSentModal.value = false
    initializeList.value = true

    emit('data-update', () => {
        initializeList.value = false
    })
}
// END: MARK AS SENT

// SEND INVOICE
const showSendInvoiceModal = ref(false)
const onSendInvoice = () => {
    showSendInvoiceModal.value = false
    initializeList.value = true

    emit('data-update', () => {
        initializeList.value = false
    })
}
// END: SEND INVOICE
</script>
