<template>
    <base-sticky-heading class="min-h-[82px] items-center">
        <template #title>
            <div class="flex flex-col gap-1">
                <div class="flex gap-x-2 gap-y-1">
                    <div class="text-lg font-medium text-gray-900">
                        {{ $t('invoices.title') }}
                    </div>
                    <base-badge
                        :label="
                            $t('invoices.numberOfInvoices', {
                                count: invoiceTotal,
                            })
                        "
                    />
                </div>
                <div
                    v-if="unpaidAmount"
                    class="text-sm font-normal text-gray-500"
                >
                    {{
                        $t('invoices.description', {
                            sum: $filters.currency(
                                unpaidAmount,
                                invoiceCurrency
                            ),
                        })
                    }}
                </div>
            </div>
        </template>

        <template #right v-if="$acl.can('create_invoices')">
            <div class="flex flex-col gap-x-3 gap-y-1 xl:flex-row">
                <base-button-dropdown size="md" variant="default">
                    <base-button
                        @click="redirectToCreate"
                        size="md"
                        variant="default"
                    >
                        {{ $t('invoices.list.add') }}
                    </base-button>

                    <template #menu-content>
                        <base-context-menu-item
                            @click="
                                $router.push({
                                    name: 'invoices.repeating.create',
                                })
                            "
                        >
                            {{ $t('invoices.list.addRecurring') }}
                        </base-context-menu-item>
                    </template>
                </base-button-dropdown>
            </div>
        </template>

        <invoice-data-grid
            :params="$route.query"
            @invoice-total="onInvoiceTotalReceived"
            table-borderless
        >
            <template #filter>
                <div class="flex w-full items-center justify-between gap-2">
                    <div class="grow">
                        <invoices-filter-views />
                    </div>
                    <div class="flex grow gap-x-3 gap-y-1">
                        <invoices-filter-search />
                        <invoices-filter-slide-out />
                    </div>
                </div>
            </template>
        </invoice-data-grid>
    </base-sticky-heading>
</template>

<script setup>
import { CURRENCIES } from '@tenant/utils/constants'

const { t } = useI18n()
useHead({
    title: t('invoices.title'),
})

const router = useRouter()

const invoiceCurrency = CURRENCIES.eur.iso

const invoiceTotal = ref(0)
const onInvoiceTotalReceived = (total) => {
    invoiceTotal.value = total
}

// GET UNPAID AMOUNT
const unpaidAmount = ref(0)
const { execute: getUnpaidAmount, result } = useApi(
    '/api/sale-documents/invoices/unpaid-amount/total',
    'GET'
)

onMounted(async () => {
    try {
        await getUnpaidAmount()
        unpaidAmount.value = result.value?.total_unpaid_amount ?? 0
    } catch (e) {
        console.error(e)
    }
})
// END: GET UNPAID AMOUNT

const redirectToCreate = () => {
    router.push({ name: 'invoices.create' })
}
</script>
