const navigation = [
    {
        id: 'sas-invoices',
        path: 'invoices',
        label: 'invoices.sidebar.label',
        position: 10,
        parent: 'sas-sales-payment',
        permission: 'view_invoices',
    },
]

export default navigation
