<template>
    <div class="flex h-screen" v-if="invoice">
        <invoices-sidebar-list :invoice="invoice" @data-update="onDataUpdate" />
        <div class="grow">
            <base-heading
                :title="invoice?.invoice_header"
                class="mb-6 px-6 py-5"
            >
                <template #title>
                    <div class="flex items-center">
                        <strong class="mr-2 text-lg font-medium text-gray-900">
                            {{ invoice?.invoice_header }}
                        </strong>

                        <partial-status
                            :status="
                                getInvoiceStatus(
                                    invoice.sale_document.status,
                                    invoice.due_date
                                )
                            "
                            variant="small"
                        >
                            <template #text>
                                {{ getStatusText(invoice) }}
                            </template>
                        </partial-status>
                    </div>
                </template>

                <template #right>
                    <div class="flex gap-3">
                        <template v-if="$acl.can('update_invoices')">
                            <template
                                v-if="
                                    invoice?.sale_document?.status ===
                                    INVOICE_STATUS.DRAFT
                                "
                            >
                                <base-button
                                    size="sm"
                                    variant="default"
                                    icon="line-icons:editor:pencil-01"
                                    @click="editDraft"
                                >
                                    {{ $t('invoices.dispatch.actions.edit') }}
                                </base-button>

                                <base-button size="sm" @click="approveDraft">
                                    {{
                                        $t('invoices.dispatch.actions.approve')
                                    }}
                                </base-button>
                            </template>

                            <template v-else>
                                <base-button
                                    v-if="
                                        invoice?.sale_document?.status ===
                                            INVOICE_STATUS.UNPAID ||
                                        invoice?.sale_document?.status ===
                                            INVOICE_STATUS.DUE
                                    "
                                    size="sm"
                                    variant="default"
                                    icon="line-icons:editor:pencil-01"
                                    @click="editDraft"
                                >
                                    {{ $t('invoices.dispatch.actions.edit') }}
                                </base-button>

                                <base-button
                                    @click="showSendInvoiceModal = true"
                                    size="sm"
                                    variant="default"
                                    icon="line-icons:communication:mail-01"
                                >
                                    {{
                                        invoice.sale_document?.sent_at
                                            ? $t(
                                                  'invoices.dispatch.actions.resend'
                                              )
                                            : $t(
                                                  'invoices.dispatch.actions.send'
                                              )
                                    }}
                                </base-button>

                                <base-button
                                    @click="printDocument(base64PdfContent)"
                                    size="sm"
                                    variant="default"
                                    icon="line-icons:media-&-devices:printer"
                                ></base-button>

                                <base-button
                                    v-if="
                                        invoice.sale_document?.is_approved &&
                                        invoice.sale_document?.status !==
                                            INVOICE_STATUS.PAID
                                    "
                                    @click="onClickRecordPayment"
                                    size="sm"
                                    icon="line-icons:finance-&-ecommerce:bank-note-01"
                                >
                                    {{
                                        $t(
                                            'invoices.dispatch.actions.recordPayment'
                                        )
                                    }}
                                </base-button>
                            </template>
                        </template>

                        <base-context-menu dropdown-classes="z-50">
                            <base-context-menu-item
                                v-if="
                                    ![
                                        INVOICE_STATUS.DRAFT,
                                        INVOICE_STATUS.PAID,
                                    ].includes(invoice.sale_document?.status) &&
                                    $acl.can('create_credit_notes')
                                "
                                icon="line-icons:finance-&-ecommerce:coins-swap-02"
                                @click="onCreateCreditNote"
                            >
                                {{
                                    $t(
                                        'invoices.dispatch.actions.createCreditNote'
                                    )
                                }}
                            </base-context-menu-item>
                            <base-context-menu-item
                                icon="line-icons:general:share-07"
                                @click="onGetShareLink"
                            >
                                {{
                                    $t('invoices.dispatch.actions.getShareLink')
                                }}
                            </base-context-menu-item>
                            <base-context-menu-item
                                icon="line-icons:general:download-01"
                                @click="onDownload"
                            >
                                {{ $t('invoices.dispatch.actions.download') }}
                            </base-context-menu-item>
                        </base-context-menu>
                    </div>
                </template>
            </base-heading>

            <base-alert
                v-if="invoice.sale_document?.status === INVOICE_STATUS.DRAFT"
                class="mx-6 mb-6"
                variant="warning"
                :title="$t('invoices.dispatch.draftNotice')"
            />

            <base-alert
                v-if="
                    !invoice.sale_document?.sent_at &&
                    invoice.sale_document?.status !== INVOICE_STATUS.DRAFT
                "
                class="mx-6 mb-6"
                variant="primary"
                :title="$t('invoices.dispatch.sendInvoiceNotice.title')"
            >
                <template #description="{ classes }">
                    <div class="flex flex-col gap-2.5 2xl:flex-row">
                        <p class="flex-1 text-sm font-normal" :class="classes">
                            {{
                                $t(
                                    'invoices.dispatch.sendInvoiceNotice.description'
                                )
                            }}
                        </p>

                        <div
                            v-if="$acl.can('update_invoices')"
                            class="flex flex-1 justify-start gap-4 2xl:justify-end"
                        >
                            <base-button @click="showSendInvoiceModal = true">{{
                                $t(
                                    'invoices.dispatch.sendInvoiceNotice.sendBtn'
                                )
                            }}</base-button>
                            <base-button
                                variant="default"
                                outline
                                :loading="isLoadingMarkAsSent"
                                @click="markAsSent"
                            >
                                {{
                                    $t(
                                        'invoices.dispatch.sendInvoiceNotice.markAsSentBtn'
                                    )
                                }}
                                <base-icon
                                    name="line-icons:arrows:arrow-right"
                                    variant="inherit"
                                />
                            </base-button>
                        </div>
                    </div>
                </template>
            </base-alert>

            <div class="px-6 pb-6">
                <div
                    v-if="
                        availableCredit &&
                        defaultCurrency &&
                        [
                            INVOICE_STATUS.UNPAID,
                            INVOICE_STATUS.PART_PAID,
                        ].includes(invoice.sale_document?.status)
                    "
                    class="mb-6 flex items-center rounded-md border border-gray-200 p-4 text-sm text-gray-900"
                >
                    <p
                        v-html="
                            $filters.sanitize(
                                $t('invoices.dispatch.creditsAvailableText', {
                                    amount: $filters.symbolCurrency(
                                        availableCredit,
                                        defaultCurrency
                                    ),
                                })
                            )
                        "
                    ></p>
                    <div
                        v-if="
                            $acl.can(['update_invoices', 'update_credit_notes'])
                        "
                        class="ml-2 cursor-pointer text-primary-700"
                        @click="showApplyModal = true"
                    >
                        {{ $t('general.applyNow') }}
                    </div>
                </div>

                <invoices-transactions-collapse
                    v-if="
                        invoice?.sale_document?.status !==
                        CREDIT_NOTE_STATUS.DRAFT
                    "
                    :loading="isLoading"
                    :credit-notes="invoice?.credit_notes ?? []"
                    :key="$route.params.id"
                    @data-update="onDataUpdate"
                />
                <base-pdf-viewer
                    class="pb-6"
                    :content="base64PdfContent"
                    :loading="isLoading"
                />

                <journal-history-collapse
                    v-if="
                        invoice.sale_document?.status !== INVOICE_STATUS.DRAFT
                    "
                    class="mb-6"
                    :loading="isLoading"
                    :queries="{
                        filter: {
                            'journalEntry.journalable_id': route.params.id,
                            'journalEntry.transaction_type':
                                JOURNAL_TRANSACTION_TYPE.INVOICE,
                        },
                    }"
                />
            </div>
        </div>
    </div>

    <invoice-send-email-modal
        :invoice="invoice"
        :show="showSendInvoiceModal"
        :loading="isSendingInvoice"
        @modal-close="showSendInvoiceModal = false"
        @modal-confirm="onSendInvoice"
    />

    <transaction-book-payment-modal
        v-if="showBookPaymentModal"
        :title="$t('invoices.bookIncomingPayment')"
        :invoice="invoice"
        @modal-close="showBookPaymentModal = false"
        @modal-confirm="onRecordPayment"
    />

    <share-link-modal
        v-if="showShareLinkModal"
        :link="shareLink"
        :can-mark-as-sent="!invoice.sale_document?.sent_at"
        @modal-close="showShareLinkModal = false"
        @mark-as-sent="markAsSent"
        :hash="generatedHash"
        type="invoice"
    />

    <invoices-status-change-confirm-modal
        :show="showStatusChangeModal"
        :loading="isLoadingMarkAsSent"
        @modal-close="showStatusChangeModal = false"
        @modal-confirm="onConfirmStatusChange"
    />

    <no-payment-account-modal
        :show="showNoPaymentAccountModal"
        @modal-close="showNoPaymentAccountModal = false"
    />

    <invoices-apply-credit-modal
        v-if="showApplyModal"
        :invoice="invoice"
        :show="showApplyModal"
        @modal-close="showApplyModal = false"
        @modal-save="onAppliedCredit"
        :key="invoice.id"
    />
</template>

<script setup>
import { useInvoice } from '@tenant/modules/tenant/invoices/composables/use-invoice'
import { INVOICE_STATUS } from '@tenant/modules/tenant/invoices/utils/constants'
import { serialize } from 'object-to-formdata'
import { useApi } from '@tenant/composables'
import { computed } from 'vue'
import cookie from '@tenant/utils/cookie'
import { JOURNAL_TRANSACTION_TYPE, STORAGE } from '@tenant/utils/constants'
import { exportPDF, printDocument } from '@tenant/utils/helper'
import { CREDIT_NOTE_STATUS } from '@tenant/modules/tenant/credit-notes/utils/constants'

const { getInvoiceStatus, getStatusText } = useInvoice()

const route = useRoute()

const invoice = ref(null)

useHead({
    title: computed(() => invoice.value?.invoice_header),
})

watch(
    () => route.params.id,
    async (id) => {
        if (route.name !== 'invoices.dispatch') {
            return
        }

        if (id) {
            await initializeData()
        }
    }
)

// GENERATE AND RENDER PDF
const { execute } = useApi(
    '/api/sale-documents/invoices/{id}/generate-pdf',
    'POST'
)

const base64PdfContent = ref(null)
const isLoading = ref(false)
const { errorNotify, successNotify } = useNotification()
const emitter = useEmitter()
const { t } = useI18n()

onMounted(async () => {
    await initializeData()
})
// END: GENERATE AND RENDER PDF
const initializeData = async () => {
    try {
        emitter.emit('set-loading', true)
        isLoading.value = true

        const result = await execute({
            params: {
                id: route.params.id,
            },
        })

        invoice.value = result.document
        base64PdfContent.value = result.content

        await getAvailableCredit({
            params: {
                id: invoice.value.id,
            },
        })
    } catch ({ errorMessage }) {
        errorNotify({
            title: errorMessage,
        })
    } finally {
        isLoading.value = false
        emitter.emit('set-loading', false)
    }
}

// HANDLE SEND INVOICE
const { handleSubmit, setErrors } = useForm({
    initialValues: {
        receiver: [],
    },
})
const showSendInvoiceModal = ref(false)
const isSendingInvoice = ref(false)
const { execute: executeSendInvoice } = useApi(
    '/api/sale-documents/invoices/{id}/send',
    'POST'
)

const onSendInvoice = handleSubmit((values) => {
    isSendingInvoice.value = true

    return executeSendInvoice({
        params: {
            id: invoice.value.id,
        },
        headers: { 'Content-Type': 'multipart/form-data' },
        data: serialize(values),
    })
        .then(async () => {
            await initializeData()

            successNotify({
                title: t('invoices.preview.saveDocument.sendSuccess'),
            })

            showSendInvoiceModal.value = false
        })
        .catch(({ errors }) => {
            setErrors(errors)
        })
        .finally(() => {
            isSendingInvoice.value = false
        })
})
// END: HANDLE SEND INVOICE

// HANDLE BOOK PAYMENT
const showStatusChangeModal = ref(false)
const showNoPaymentAccountModal = ref(false)

const onClickRecordPayment = () => {
    if (!invoice.value?.sale_document?.sent_at) {
        showStatusChangeModal.value = true
    } else {
        if (!paymentAccounts.value.length) {
            showNoPaymentAccountModal.value = true
            return
        }

        showBookPaymentModal.value = true
    }
}

const { paymentAccounts } = usePaymentAccounts(
    invoice.value?.sale_document?.currency_id
)

const onConfirmStatusChange = async () => {
    try {
        await markAsSent()
    } finally {
        showStatusChangeModal.value = false
        showBookPaymentModal.value = true
    }
}

const showBookPaymentModal = ref(false)
const onRecordPayment = () => {
    showBookPaymentModal.value = false
    successNotify({
        title: t('transaction.bookPaymentSuccess'),
    })

    initializeData()
}
// END: HANDLE BOOK PAYMENT

// HANDLE APPROVE INVOICE
const { execute: executeApproveDraft } = useApi(
    '/api/sale-documents/invoices/{id}/approve-draft',
    'POST'
)

const router = useRouter()
const editDraft = () => {
    router.push({
        name: 'invoices.edit',
        params: {
            id: route.params.id,
        },
    })
}

const approveDraft = async () => {
    try {
        emitter.emit('set-loading', true)

        await executeApproveDraft({
            params: {
                id: invoice.value.id,
            },
        })

        await initializeData()
    } catch ({ errorMessage }) {
        errorNotify({
            title: errorMessage,
        })
    } finally {
        emitter.emit('set-loading', false)
    }
}
// END: HANDLE APPROVE INVOICE

// MARK AS SENT
const { execute: executeMarkAsSent } = useApi(
    '/api/sale-documents/invoices/mark-as-sent',
    'POST'
)

const isLoadingMarkAsSent = ref(false)
const markAsSent = async () => {
    try {
        isLoadingMarkAsSent.value = true

        await executeMarkAsSent({
            data: {
                ids: [route.params.id],
            },
        })

        await initializeData()
    } catch ({ errorMessage }) {
        errorNotify({
            title: errorMessage,
        })
    } finally {
        isLoadingMarkAsSent.value = false
    }
}
// END: MARK AS SENT

// GET SHARE LINK
const isGeneratingHash = ref(false)
const showShareLinkModal = ref(false)
const shareLink = ref('')
const generatedHash = ref('')
const onGetShareLink = async () => {
    const { execute } = useApi(
        '/api/sale-documents/invoices/{id}/generate-hash',
        'POST'
    )

    try {
        if (shareLink.value) {
            showShareLinkModal.value = true
            return
        }

        isGeneratingHash.value = true
        const { hash } = await execute({
            params: {
                id: route.params.id,
            },
        })

        generatedHash.value = hash

        isGeneratingHash.value = false
        const shareLinkRoute = router.resolve({
            name: 'invoices.share',
            params: {
                tenantId: cookie.getItem(STORAGE.TENANT_ID),
                hash,
            },
        })

        shareLink.value = `${window.location.origin}${shareLinkRoute.href}`

        showShareLinkModal.value = true
    } catch ({ errorMessage }) {
        errorNotify({
            title: errorMessage,
        })
    }
}
// END: GET SHARE LINK

const onDataUpdate = async (cb) => {
    await initializeData()
    if (cb && cb instanceof Function) {
        cb()
    }
}

const onDownload = () => {
    exportPDF(`${invoice.value.invoice_header}.pdf`, base64PdfContent.value)
}

const onCreateCreditNote = () => {
    router.push({
        name: 'credit-notes.create',
        query: {
            invoice_id: route.params.id,
        },
    })
}

// CREDIT NOTES
const showApplyModal = ref(false)

const { execute: getAvailableCredit, result: availableCredit } = useApi(
    '/api/invoice/{id}/credits-amount-available',
    'POST'
)
const { defaultCurrency } = useCurrencies()

const onAppliedCredit = async () => {
    await initializeData()
    showApplyModal.value = false
}
// END: CREDIT NOTES
</script>
