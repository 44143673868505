export default {
    editInvoice: {
        title: 'Edit invoice',
    },
    createInvoice: {
        title: 'New invoice',
        description:
            'Manage your team members and their account permissions here.',
        preview: 'Preview',
        saveAsDraft: 'Save as draft',
        proceedBtn: 'Save and continue',
        customerInfoTitle: 'Content and invoice information',
        customerLabel: 'Customer *',
        customerPlaceholder: 'Search / create contact',
        customerAddressLabel: 'Address',
        customerAddressPlaceholder: 'Enter an address...',
        customerCountryLabel: 'Country *',
        invoiceHeaderLabel: 'Invoice header *',
        invoiceHeaderPlaceholderValue: 'Invoice no. {number}',
        invoiceHeaderPrefillPlaceholderValue:
            'Invoice no. {number} for proposal {proposalNumber}',
        invoiceHeaderPlaceholder: 'Invoice no. RE-1020',
        invoiceNumberLabel: 'Invoice number *',
        invoiceNumberPlaceholder: 'RE-1020',
        dateOfInvoiceLabel: 'Date of invoice *',
        paymentTermsLabel: 'Payment Terms',
        dueDateOfInvoiceLabel: 'Due date',
        invoiceDateOfDeliveryLabel: 'Date of delivery',
        invoiceReferenceNoLabel: 'Reference / Order No.',
        invoiceSameDateToggleLabel: 'Deliver date like invoice date?',
        headerContentTitle: 'Header content',
        footerContentTitle: 'Footer content',
        lineItemsTitle: 'Products and items',
        lineItemsExchangeRate: '(As on {now}) 1 {fromIso} = {factor} {toIso}',
        moreOptionsLabel: 'More options',
        currencyLabel: 'Currency',
        cashDiscountLabel: 'Cash discount',
        cashDiscountSuffix: ' Days',
        cashDiscountPlaceholder: '0 Days',
        cashDiscountPercentsLabel: 'Percents',
        cashDiscountPercentsSuffix: ' %',
        cashDiscountPercentsPlaceholder: '0 %',
        contactPersonLabel: 'Internal contact person',
        vatRuleTitle: 'VAT RULE',
        vatRuleFirst: 'Identify VAT code',
        vatRuleSecond: 'Tax free intra-community delivery (EU)',
        vatRuleThird:
            'Tax obligation of the beneficiary (outside the EU, e.g. Switzerland)',
        netAmount: 'Net amount (inc. discount/surcharge)',
        vat: 'VAT {n}%',
        total: 'Total',
    },
    createRepeatingInvoice: {
        title: 'New Repeating Invoice',
        repeatLabel: 'Repeat this transaction every',
        endDateLabel: 'End date (optional)',
        invoiceDateLabel: 'Invoice date',
        saveAsDraft: 'Save as draft',
        approve: 'Approve',
        invoiceDateNotice:
            'The invoice date will be set on invoice creation date.',
        repeatOptions: {
            weekly: 'Week',
            monthly: 'Month',
        },
        dueDateOptions: {
            followingMonth: 'The following month',
            dayAfterInvoiceDate: 'Day(s) after the invoice date',
            dayAfterInvoiceMonth: 'Day(s) after the end of the invoice month',
            currentMonth: 'Of the current month',
        },
    },
    editRepeatingInvoice: {
        title: 'Edit Repeating Invoice',
        successMsg: 'Repeating invoice successfully updated!',
    },
    invoices: {
        title: 'Invoices',
        description: 'Sum of unpaid amounts: {sum}',
        emptyText: 'No invoices found',
        emptySubText: 'Go ahead an create your first invoice',
        bookIncomingPayment: 'Book incoming payment',
        numberOfInvoices: 'no Invoices | 1 Invoice | {count} Invoices',
        editExchangeRate: 'Edit Exchange Rate',
        exchangeRateLabel: 'Exchange Rate *',
        recalculateLabel: 'Re-calculate item prices based on this rate',

        general: {
            saveSuccess: 'Invoice successfully created!',
            copySuccess: 'Invoice successfully copied!',
            saveAsDraftSuccess: 'Invoice successfully saved as draft!',
        },

        sidebar: {
            label: 'Invoices',
        },

        list: {
            add: 'Add invoice',
            addRecurring: 'Add repeating invoice',
            status: 'Status',
            dueDate: 'Due Date',
            number: 'Invoice#',
            customer: 'Customer Name',
            date: 'Date',
            amountNet: 'Amount (Net)',
            amountGross: 'Amount',
            unpaidGross: 'Balance Due',
            orderNo: 'Order Number',
            viewAll: 'View All',
            draft: 'Draft',
            unpaid: 'Unpaid',
            due: 'Due',
            paid: 'Paid',
            partPaid: 'Part-paid',
            repeating: 'Repeating',
            searchByInvoiceNumber: 'Search by invoice number',
            contextMenu: {
                send: 'Send',
                bookInComing: 'Book incoming payment',
                download: 'Download',
                preview: 'Preview',
                addTask: 'Add task',
                addDelivery: 'Add delivery note',
                copyInvoice: 'Copy invoice',
                paymentReminder: 'Payment reminder',
                editDocument: 'Edit document',
                markAsUnpaid: 'Mark as unpaid',
                receivedInfo: 'Sent payment received information',
                cancelInvoice: 'Cancel invoice',
            },
            filters: {
                netAmount: 'Amount (net)',
            },
        },
        preview: {
            title: 'Preview document as draft',
            headline: 'Preview document as draft',
            print: 'Print',
            download: 'Download',
            saveAsDraft: 'Save as draft',
            settings: {
                headline: 'Settings',
                language: {
                    label: 'Language',
                    placeholder: 'Language',
                },
                template: {
                    label: 'Template',
                    placeholder: 'Template',
                },
                letterhead: {
                    label: 'Letterhead',
                    placeholder: 'Letterhead',
                    create: 'Create letterpaper',
                },
                qrCode: {
                    label: 'QR code',
                },
                productNumber: {
                    label: 'Product number',
                },
                taxByLineItems: {
                    label: 'Tax by line item',
                },
                foldlines: {
                    label: 'Foldlines',
                },
            },
            saveDocument: {
                headline: 'Save document',
                saveAsDraft: 'Save as draft',
                save: 'Save document',
                sendSuccess: 'Invoice successfully sent!',
                saveSuccess: 'Invoice successfully created!',
                saveAsDraftSuccess: 'Invoice successfully saved as draft!',
                warningModal: {
                    title: 'Invoice is fixed after dispatch',
                    description:
                        'According to GoD, outgoing invoices may no longer be changeable after dispatch to the customer.' +
                        '<br><br>' +
                        'In the settings the fixing can be deactivated by default.' +
                        '<br><br>' +
                        'GoBD = Principles for the proper keeping and safekeeping of books, records and documents in electronic form as well as for data access.',
                },
            },
            sendDocument: {
                headline: 'Send document',
                receiver: {
                    label: 'Receiver *',
                    placeholder: 'Receiver',
                },
                cc: {
                    label: 'CC:',
                    placeholder: "cc{'@'}example.com",
                },
                bcc: {
                    label: 'BCC:',
                    placeholder: "bcc{'@'}example.com",
                },
                subject: {
                    label: 'Subject *',
                    placeholder: 'Invoice no. RE-1020',
                },
                message: {
                    label: 'Message',
                },
                signature: {
                    label: 'Signature',
                },
                attachment: {
                    label: 'Attachment',
                    description: 'SVG, PNG, JPG or GIF (max. 800x400px)',
                },
                sendByEmail: 'Send by email',
                buttonsDeviderText: 'Or send by post',
                sendLetter: 'Send letter for €1.75',
                mailingSmallText:
                    'Utilize our mailing api to save up to 50% processing cost',
            },
        },
        save: {
            title: 'Save document',
            headline: 'Save document',
            saveDocument: 'Save document',
        },
        dispatch: {
            title: 'Invoice no. RE-1031',
            headline: 'Invoice no. RE-1031',
            paymentReminder: 'Payment reminder',
            bookIncomingPayment: 'Book incoming payment',
            dueHeadline: 'Due',
            creditsAvailableText: `Credits Available: <b class="ml-1">{amount}</b>`,
            overview: {
                status: 'Status',
                customer: 'Customer',
                amountDue: 'Amount due',
                due: 'Due',
            },
            actions: {
                moreActions: 'More actions',
                sendDocument: 'Send document',
                addTask: 'Add task',
                addDeliveryNote: 'Add delivery note',
                copyInvoice: 'Copy invoice',
                download: 'Download',
                editDocument: 'Edit document',
                cancelInvoice: 'Cancel invoice',
                exportPdf: 'Export as PDF',
                print: 'Print',
                cancel: 'Cancel',
                createAnotherInvoice: 'Create another invoice',
                send: 'Send',
                resend: 'Resend',
                edit: 'Edit',
                approve: 'Approve Draft',
                recordPayment: 'Record a payment',
                sendReminder: 'Send reminder',
                getShareLink: 'Get Share Link',
                createCreditNote: 'Create Credit Note',
                delete: 'Delete',
            },
            sidebar: {
                filter: {
                    allInvoices: 'All invoices',
                },
            },
            netAmount: {
                label: 'Amount (Net)',
            },
            grossAmount: {
                label: 'Amount (Gross)',
            },
            unpaidGrossAmount: {
                label: 'Unpaid amount (Gross)',
            },
            customer: {
                label: 'Customer',
            },
            dueDate1: {
                label: 'Due date',
            },
            dueDate2: {
                label: 'Due date',
            },
            sentOn: {
                label: 'Sent on',
            },
            enshrined: {
                label: 'Enshrined',
            },
            tags: {
                label: 'Tags',
            },
            today: 'Today',
            invoiceCancel: {
                title: 'Cancel invoice',
                description:
                    'This process creates a cancellation invoice directly. It is then no longer possible to process the canceled outgoing invoice. Are you sure you want to cancel the invoice?',
                successMessage: 'Invoice has been cancelled',
            },
            draftNotice:
                'This is a DRAFT invoice. You can take further actions once you approve it.',
            sendInvoiceNotice: {
                title: 'Send the invoice',
                description:
                    'Invoice has been created. You can email it to your customer or mark it as sent.',
                sendBtn: 'Send invoice',
                markAsSentBtn: 'Mark as sent',
            },
            createCard: {
                title: 'Create',
                createdLabel: 'Created: ',
                approveDraft: 'Approve draft',
                editDraft: 'Edit draft',
            },
            sendCard: {
                title: 'Send',
                lastSentLabel: 'Last sent: ',
                sendInvoice: 'Send invoice',
                resendInvoice: 'Resend invoice',
                neverText: 'Never',
                orText: 'or',
                markAsSent: 'Mark as sent',
                getShareLink: 'Get share link',
            },
            getPaidCard: {
                title: 'Get paid',
                amountDueLabel: 'Amount due: ',
                recordPayment: 'Record a payment',
                manuallyText: 'manually',
                sendReminder: 'Send reminder',
                nowText: 'now',
                paymentsReceived: 'Payments received:',
                sendBill: 'Send a bill',
                editPayment: 'Edit payment',
                removePayment: 'Remove payment',
                status: 'Status:',
                paidInFullText: 'Your invoice is paid in full',
                awaitingPaymentText: 'Your invoice is awaiting payment',
            },
            transactions: {
                title: 'Payments Received',
                columns: {
                    date: 'Date',
                    amount: 'Amount',
                    paymentId: 'Payment #',
                    reference: 'Reference #',
                },
                description:
                    '{date} - A payment for {amount} was made using a bank payment.',
            },
            journal: {
                title: 'Journal history',
                columns: {
                    account: 'Account',
                    debit: 'DEBIT',
                    credit: 'CREDIT',
                },
            },
            statusChangeModal: {
                title: 'Status will change',
                description:
                    "Invoice status will be changed to 'Sent' once the payment is recorded.",
            },
            markAsSentModal: {
                title: 'Mark invoices as sent',
                description:
                    'All selected invoices will be marked as sent. If you’ve selected a DRAFT invoice, it will be approved and also marked as sent.',
                confirmBtn: 'Yes, mark as sent',
                successMsg: 'Invoices marked as sent successfully',
            },
            sendEmailsModal: {
                title: 'How would you like to send you invoices?',
                description:
                    'Invoice will be send to email addresses selected at the invoice level.',
                sendToText: 'Send to',
                emailText: 'Email',
                sendBtn: 'Send Email',
                successMsg: 'Emails sent successfully',
            },
            sidebarList: {
                markAsSentBtn: 'Mark as sent',
                sendEmailsBtn: 'Send Emails',
                selectedText: '{count} selected',
            },
        },
        deleteModal: {
            title: 'Delete invoice',
            description: 'Are you sure you want to delete this invoice?',
        },
        appliedCredits: {
            title: 'Credits Applied',
            date: 'Date',
            creditNo: 'Credit Note#',
            creditsApplied: 'Credits Applied',
            deleteSuccess: 'Credits have been removed from the invoice',
            deleteConfirmText:
                'Are you sure you want to delete the credits applied to invoice?',
        },
        exchangeRateModal: {
            title: 'Edit Exchange Rate',
            exchangeRateLabel: 'Exchange Rate *',
            recalculateLabel: 'Re-calculate item prices based on this rate',
        },
        applyCreditModal: {
            title: 'Apply credits for {number}',
            creditNoteNoColumn: 'Credit note#',
            dateColumn: 'Credit note date',
            creditAmountColumn: 'Credit amount',
            creditsAvailableColumn: 'Credits available',
            amountToCreditColumn: 'Amount to credit',
            amountToCreditText: 'Amount to credit: {amount}',
            invoiceBalanceDue: 'Invoice Balance Due: {amount}',
            applySuccessMsg: 'Credits have been applied to the invoice(s)',
        },
    },
}
