<template>
    <base-sticky-heading :title="$t('editInvoice.title')" class="px-6 py-3.5">
        <template #right>
            <div class="flex gap-3">
                <base-button outline variant="default" @click="onCancel">
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    outline
                    variant="default"
                    @click="onPreview"
                    icon="line-icons:general:search-lg"
                    :loading="isSaving"
                >
                    {{ $t('createInvoice.preview') }}
                </base-button>
                <base-button
                    variant="primary"
                    icon="line-icons:general:check"
                    @click="onProceed"
                    :loading="isSaving"
                >
                    {{ $t('createInvoice.proceedBtn') }}
                </base-button>
            </div>
        </template>

        <div class="p-6">
            <create-invoice-information v-if="invoice" />
            <create-invoice-header-content />
            <create-invoice-line-items is-edit />
            <create-invoice-footer-content />
            <create-invoice-more-options />
            <create-invoice-amount />
        </div>
    </base-sticky-heading>

    <transaction-book-close-modal
        :code="code"
        :date="parameters?.date"
        @modal-close="code = null"
    />
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useForm } from 'vee-validate'
import { useHead } from '@vueuse/head'
import { dayjsFormat } from '@tenant/utils/day'
import { DATE_SERVER_FORMAT } from '@tenant/utils/constants'
import { isNil, omitBy } from 'lodash-es'

const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const { setValues, setErrors, values, handleSubmit } = useForm()
const { useApiGet } = useApiFactory('sale-documents/invoices')
const { execute: executeGet } = useApiGet()
const emitter = useEmitter()
const { defaultCurrency } = useCurrencies()

useHead({
    title: computed(() => values?.invoice_header ?? t('editInvoice.title')),
})

const onCancel = () => {
    router.push({ name: 'invoices.list' })
}

const onPreview = handleSubmit(async (values) => {
    const data = await saveInvoice(values)

    if (data?.id) {
        await router.push({
            name: 'invoices.dispatch',
            params: { id: data.id },
        })
    }
})

const onProceed = handleSubmit(async (values) => {
    const data = await saveInvoice(values)

    if (data?.id) {
        await router.push({
            name: 'invoices.dispatch',
            params: { id: data.id },
        })
    }
})

const invoice = ref(null)
onMounted(async () => {
    try {
        emitter.emit('set-loading', true)

        invoice.value = await executeGet(route.params.id)
        // Convert line items' values to number
        const lineItems = invoice.value.line_items.map((item) => ({
            ...item.sale_document_line_item,
            tax_rate: Number(item.sale_document_line_item.tax_rate),
            discount: Number(item.sale_document_line_item.discount),
            quantity: Number(item.sale_document_line_item.quantity),
        }))

        setValues({
            ...invoice.value,
            ...invoice.value.sale_document,
            contact_id: invoice.value.sale_document.contact.contact_id,
            contact: invoice.value.sale_document.contact.contact,
            line_items: lineItems,
            address: invoice.value.sale_document.address,
            exchange_rate: invoice.value.sale_document.exchange_rate,
            contact_currency: invoice.value.sale_document.contact.currency,
        })
    } finally {
        emitter.emit('set-loading', false)
    }
})

const { useApiSave } = useApiFactory('sale-documents/invoices')
const { execute, loading: isSaving, code, parameters } = useApiSave()

const saveInvoice = async (values) => {
    try {
        return await execute(
            {
                ...omitBy(values, isNil),
                currency_id: values.currency_id ?? defaultCurrency.value.value,
                due_date: dayjsFormat(values.due_date, DATE_SERVER_FORMAT),
                date_of_invoice: dayjsFormat(
                    values.date_of_invoice,
                    DATE_SERVER_FORMAT
                ),
                date_of_delivery: dayjsFormat(
                    values.date_of_delivery,
                    DATE_SERVER_FORMAT
                ),
            },
            route.params.id
        )
    } catch ({ errors }) {
        setErrors(errors)
    }
}
</script>
