<template>
    <create-invoice-information>
        <template #right>
            <div class="col-span-2 flex gap-2">
                <div class="flex flex-1 flex-col">
                    <form-control-label
                        :label="$t('createRepeatingInvoice.repeatLabel')"
                    />
                    <div class="flex gap-2">
                        <form-text-input
                            v-model="repeatFrequencyValue"
                            :error-message="errorRepeatFrequencyValue"
                            class="w-[65px]"
                            :full-width="false"
                        />
                        <form-single-select
                            v-model="repeatFrequencyType"
                            :error-message="errorRepeatFrequencyType"
                            :options="repeatOptions"
                            :show-clear="false"
                        />
                    </div>
                </div>

                <form-date-picker
                    :disabled="!!$route.params.id"
                    v-model="dateOfInvoice"
                    :error-message="errorDateOfInvoice"
                    class="flex-1"
                    :label="$t('createRepeatingInvoice.invoiceDateLabel')"
                    single
                    auto-apply
                />
            </div>

            <div class="col-span-2 flex gap-2">
                <div class="flex flex-1 flex-col">
                    <form-control-label
                        :label="$t('createInvoice.dueDateOfInvoiceLabel')"
                    />
                    <div class="flex gap-2">
                        <form-text-input
                            v-model="repeatDueDateValue"
                            :error-message="errorRepeatDueDateValue"
                            class="w-[65px]"
                            :full-width="false"
                        />
                        <form-single-select
                            v-model="repeatDueDateType"
                            :error-message="errorRepeatDueDateType"
                            :options="dueDateOptions"
                            :show-clear="false"
                        />
                    </div>
                </div>

                <form-date-picker
                    v-model="repeatUntil"
                    :error-message="errorRepeatUntil"
                    class="flex-1"
                    :label="$t('createRepeatingInvoice.endDateLabel')"
                    single
                    auto-apply
                />
            </div>

            <div class="col-span-2 flex w-full gap-3">
                <form-radio-group-item
                    v-model="repeatStatus"
                    name="uploadType"
                    value="draft"
                    class="flex-1"
                    bullet-position="left"
                    bullet-icon=""
                >
                    <p class="block text-sm font-medium text-gray-700">
                        {{ $t('createRepeatingInvoice.saveAsDraft') }}
                    </p>
                </form-radio-group-item>
                <form-radio-group-item
                    v-model="repeatStatus"
                    name="uploadType"
                    value="approve"
                    class="flex-1"
                    bullet-position="left"
                    bullet-icon=""
                >
                    <p class="block text-sm font-medium text-gray-700">
                        {{ $t('createRepeatingInvoice.approve') }}
                    </p>
                </form-radio-group-item>
            </div>

            <div
                class="col-span-2 flex items-center gap-2.5 text-sm text-gray-500"
            >
                <base-icon
                    name="line-icons:general:info-circle"
                    variant="gray"
                />
                {{ $t('createRepeatingInvoice.invoiceDateNotice') }}
            </div>
        </template>
    </create-invoice-information>
</template>
<script setup>
import { now, shortServerFormat } from '@tenant/utils/day'
import {
    REPEAT_DUE_DATE,
    REPEAT_EVERY_TYPE_MONTH,
    REPEAT_EVERY_TYPE_WEEK,
} from '@tenant/modules/tenant/invoices/utils/constants'

const { t } = useI18n()

const { value: dateOfInvoice, errorMessage: errorDateOfInvoice } = useField(
    'date_of_invoice',
    undefined,
    {
        initialValue: shortServerFormat(now()),
    }
)
const { value: repeatStatus } = useField('repeating.repeat_status', undefined, {
    initialValue: 'draft',
})
const { value: repeatFrequencyValue, errorMessage: errorRepeatFrequencyValue } =
    useField('repeating.repeat_every_value', undefined, {
        initialValue: 1,
    })
const { value: repeatFrequencyType, errorMessage: errorRepeatFrequencyType } =
    useField('repeating.repeat_every_type', undefined, {
        initialValue: REPEAT_EVERY_TYPE_MONTH,
    })
const { value: repeatDueDateValue, errorMessage: errorRepeatDueDateValue } =
    useField('repeating.repeat_due_date_value', undefined, {
        initialValue: 1,
    })
const { value: repeatDueDateType, errorMessage: errorRepeatDueDateType } =
    useField('repeating.repeat_due_date_type', undefined, {
        initialValue: REPEAT_DUE_DATE.FOLLOWING_MONTH,
    })
const { value: repeatUntil, errorMessage: errorRepeatUntil } = useField(
    'repeating.repeat_until'
)

const repeatOptions = [
    {
        label: t('createRepeatingInvoice.repeatOptions.monthly'),
        value: REPEAT_EVERY_TYPE_MONTH,
    },
    {
        label: t('createRepeatingInvoice.repeatOptions.weekly'),
        value: REPEAT_EVERY_TYPE_WEEK,
    },
]

const dueDateOptions = [
    {
        label: t('createRepeatingInvoice.dueDateOptions.followingMonth'),
        value: REPEAT_DUE_DATE.FOLLOWING_MONTH,
    },
    {
        label: t('createRepeatingInvoice.dueDateOptions.dayAfterInvoiceDate'),
        value: REPEAT_DUE_DATE.AFTER_INVOICE_DATE,
    },
    {
        label: t('createRepeatingInvoice.dueDateOptions.dayAfterInvoiceMonth'),
        value: REPEAT_DUE_DATE.AFTER_INVOICE_DATE_END_OF_MONTH,
    },
    {
        label: t('createRepeatingInvoice.dueDateOptions.currentMonth'),
        value: REPEAT_DUE_DATE.CURRENT_MONTH,
    },
]
</script>
