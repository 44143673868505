<template>
    <base-sticky-heading
        :title="$t('editRepeatingInvoice.title')"
        class="px-6 py-3.5"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button outline variant="default" @click="onCancel">
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    variant="primary"
                    icon="line-icons:general:check"
                    @click="onProceed"
                    :loading="isSaving"
                >
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>

        <div class="p-6">
            <create-repeating-invoice-information v-if="invoice" />
            <create-invoice-header-content />
            <create-invoice-line-items is-edit />
            <create-invoice-footer-content />
            <create-invoice-more-options />
            <create-invoice-amount />
        </div>
    </base-sticky-heading>

    <transaction-book-close-modal
        :code="code"
        :date="parameters?.date"
        @modal-close="code = null"
    />
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useForm } from 'vee-validate'
import { dayjsFormat } from '@tenant/utils/day'
import { DATE_SERVER_FORMAT } from '@tenant/utils/constants'
import { isNil, omitBy } from 'lodash-es'

const { errorNotify, successNotify } = useNotification()
const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const { setValues, setErrors, values, handleSubmit } = useForm()
const { useApiGet, entity: invoice } = useApiFactory('sale-documents/invoices')
const { execute: executeGet } = useApiGet()
const emitter = useEmitter()
const { defaultCurrency } = useCurrencies()

useHead({
    title: computed(
        () => values?.invoice_header ?? t('editRepeatingInvoice.title')
    ),
})

const onCancel = () => {
    router.push({ name: 'invoices.list' })
}
const onProceed = handleSubmit(async (values) => {
    try {
        const data = await saveInvoice(values)

        successNotify({
            text: t('editRepeatingInvoice.successMsg'),
        })

        if (data?.id) {
            await router.push({
                name: 'invoices.list',
            })
        }
    } catch ({ errorMessage }) {
        errorNotify({
            text: errorMessage,
        })
    }
})

onMounted(async () => {
    try {
        emitter.emit('set-loading', true)

        await executeGet(route.params.id)
        // Convert line items' values to number
        const lineItems = invoice.value.line_items.map((item) => ({
            ...item.sale_document_line_item,
            tax_rate: Number(item.sale_document_line_item.tax_rate),
            discount: Number(item.sale_document_line_item.discount),
            quantity: Number(item.sale_document_line_item.quantity),
        }))

        setValues({
            ...invoice.value,
            ...invoice.value.sale_document,
            contact_id: invoice.value.sale_document.contact.contact_id,
            contact: invoice.value.sale_document.contact.contact,
            line_items: lineItems,
            address: invoice.value.sale_document.address,
            exchange_rate: invoice.value.sale_document.exchange_rate,
            contact_currency: invoice.value.sale_document.contact.currency,
            repeating: invoice.value.repeater,
        })
    } finally {
        emitter.emit('set-loading', false)
    }
})

const { useApiSave } = useApiFactory('invoice-repeaters')
const { execute, loading: isSaving, code, parameters } = useApiSave()

const saveInvoice = async (values) => {
    try {
        if (!values?.repeater?.invoice_id) return

        return await execute(
            {
                ...omitBy(values, isNil),
                currency_id: values.currency_id ?? defaultCurrency.value.value,
                due_date: dayjsFormat(values.due_date, DATE_SERVER_FORMAT),
                date_of_invoice: dayjsFormat(
                    values.date_of_invoice,
                    DATE_SERVER_FORMAT
                ),
                date_of_delivery: dayjsFormat(
                    values.date_of_delivery,
                    DATE_SERVER_FORMAT
                ),
                repeating: {
                    ...values.repeating,
                    repeat_until: dayjsFormat(
                        values.repeating.repeat_until,
                        DATE_SERVER_FORMAT
                    ),
                },
            },
            values.repeater.invoice_id
        )
    } catch ({ errors }) {
        setErrors(errors)
    }
}
</script>
