<template>
    <base-sticky-heading
        :title="$t('createRepeatingInvoice.title')"
        class="px-6 py-3.5"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button
                    outline
                    variant="default"
                    @click="onCancel"
                    :disabled="isSaving"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    variant="primary"
                    icon="line-icons:general:check"
                    @click="onProceed"
                    :loading="isSaving"
                >
                    {{ $t('createInvoice.proceedBtn') }}
                </base-button>
            </div>
        </template>

        <div class="p-6">
            <create-repeating-invoice-information />
            <create-invoice-header-content :default-content="headerContent" />
            <create-invoice-line-items />
            <create-invoice-footer-content :default-content="footerContent" />
            <create-invoice-more-options />
            <create-invoice-amount />
        </div>
    </base-sticky-heading>

    <transaction-book-close-modal
        :code="code"
        :date="parameters?.date"
        @modal-close="code = null"
    />
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { INVOICE_STATUS } from '@tenant/modules/tenant/invoices/utils/constants'
import { useTextTemplate } from '@tenant/composables/apis/use-text-template'
import { shortServerFormat } from '@tenant/utils/day'
import { PAYMENT_TERMS } from '@tenant/utils/constants'
const emitter = useEmitter()

const { t } = useI18n()
const router = useRouter()
const { setErrors, handleSubmit } = useForm()

useHead({
    title: t('createRepeatingInvoice.title'),
})

onMounted(async () => {
    try {
        textTemplates.value = await getDefaultTextTemplate(
            'document',
            'invoices'
        )

        emitter.emit('set-loading', false)
    } finally {
        emitter.emit('set-loading', false)
    }
})

const onCancel = () => {
    router.push({ name: 'invoices.list' })
}
const onProceed = handleSubmit(async (values) => {
    const data = await saveInvoice(values)

    if (data?.id) {
        await router.push({
            name: 'invoices.list',
            params: { id: data.id },
        })
    }
})

const store = useStore()
const currentUser = computed(() => store.getters.user)
const { useApiCreate } = useApiFactory('invoice-repeaters')
const { execute, loading: isSaving, code, parameters } = useApiCreate()
const { defaultCurrency } = useCurrencies()
const { errorNotify } = useNotification()

const saveInvoice = async (values, status = INVOICE_STATUS.DRAFT) => {
    try {
        return await execute({
            ...values,
            currency_id: values.currency_id ?? defaultCurrency.value.value,
            user_id: currentUser.value.id,
            status,
            payment_term: PAYMENT_TERMS.CUSTOM,
        })
    } catch ({ errors, errorMessage, hasErrors }) {
        if (hasErrors) {
            setErrors(errors)
        } else {
            errorNotify({
                text: errorMessage,
            })
        }
    }
}

// Get default text template for invoice
const { getDefaultTextTemplate } = useTextTemplate()
const textTemplates = ref(null)

const footerContent = computed(() => {
    return (
        textTemplates.value?.find(
            (template) => template.line_item === 'footer_content'
        )?.content ?? ''
    )
})

const headerContent = computed(() => {
    return (
        textTemplates.value?.find(
            (template) => template.line_item === 'header_content'
        )?.content ?? ''
    )
})

const contactCurrency = useFieldValue('contact_currency')
const { setValue: setExchangeRate } = useField('exchange_rate')
watchEffect(() => {
    if (!contactCurrency.value) {
        return
    }

    const { rate, rate_updated_at } = contactCurrency.value
    setExchangeRate({
        rate,
        date: shortServerFormat(rate_updated_at),
    })
})
</script>
