import { GUARD } from '@tenant/utils/constants'
const routes = [
    {
        name: 'invoices',
        path: '/invoices',
        redirect: {
            name: 'invoices.list',
        },
        children: [
            {
                name: 'invoices.list',
                path: '',
                component: () => import('./pages/invoices-list.vue'),
            },
            {
                name: 'invoices.create',
                path: 'create',
                component: () => import('./pages/invoices-create.vue'),
                meta: {
                    permission: 'create_invoices',
                },
            },
            {
                name: 'invoices.edit',
                path: ':id',
                component: () => import('./pages/invoices-edit.vue'),
                meta: {
                    permission: 'update_invoices',
                },
            },
            {
                name: 'invoices.dispatch',
                path: 'dispatch/:id',
                component: () => import('./pages/invoices-dispatch.vue'),
            },
            {
                name: 'invoices.repeating.create',
                path: 'repeating/create',
                component: () =>
                    import('./pages/invoices-repeating-create.vue'),
                meta: {
                    permission: 'create_invoices',
                },
            },
            {
                name: 'invoices.repeating.edit',
                path: 'repeating/edit/:id',
                component: () => import('./pages/invoices-repeating-edit.vue'),
                meta: {
                    permission: 'update_invoices',
                },
            },
        ],
    },
    {
        name: 'invoices.share',
        path: '/share/:tenantId/:hash',
        component: () => import('./pages/invoices-share.vue'),
        meta: { guard: GUARD.LINK },
    },
    {
        name: 'invoice.share-preview',
        path: '/share-preview/:tenantId/:hash',
        component: () => import('./pages/invoices-share.vue'),
        meta: { guard: GUARD.LINK },
    },
]

export default {
    routes,
    meta: {
        layout: 'dashboard-layout',
        guard: GUARD.TENANT,
        permission: 'view_invoices',
    },
}
