import { isNil, omitBy } from 'lodash-es'
import { INVOICE_STATUS } from '@tenant/modules/tenant/invoices/utils/constants'
import { dateShortFormat, now } from '@tenant/core/filter'
import { dayDiff } from '@tenant/utils/day'

export const useInvoice = () => {
    /**
     * Handle save invoice and increment invoice number
     * @param invoice
     * @returns {Promise<T>}
     */
    const saveInvoice = async (invoice) => {
        const { result: invoiceNumber, execute } = useApi(
            '/api/sequence-settings/generate-number/invoice',
            'GET'
        )

        await execute()

        const { useApiCreate } = useApiFactory('sale-documents/invoices')
        const { execute: executeSave } = useApiCreate()

        return executeSave({
            ...omitBy(invoice, isNil),
            due_date: dateShortFormat(invoice.due_date),
            date_of_invoice: dateShortFormat(invoice.date_of_invoice),
            date_of_delivery: dateShortFormat(invoice.date_of_delivery),
            number: invoiceNumber.value,
            status: INVOICE_STATUS.DISPATCH,
        })
    }

    const getInvoiceStatus = (status, dueDate) => {
        if (status === INVOICE_STATUS.UNPAID && dayDiff(dueDate, now()) <= 0) {
            return INVOICE_STATUS.DUE
        }

        return status
    }

    const getStatusText = (item) => {
        const { t } = useI18n()
        if (!item?.sale_document?.status) return

        const status = getInvoiceStatus(
            item?.sale_document?.status,
            item.due_date
        )

        if (status === 'due') {
            return t(
                `general.statuses.${status}`,
                Math.abs(dayDiff(item.due_date, now()))
            )
        }

        return t(`general.statuses.${status}`)
    }

    return {
        saveInvoice,
        getInvoiceStatus,
        getStatusText,
    }
}
