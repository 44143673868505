export const LINE_ITEM_TYPES = {
    LINE_ITEM: 'line_item',
    DISCOUNT: 'discount',
    SURCHARGE: 'surcharge',
}

export const INVOICE_STATUS = {
    DISPATCH: 'dispatch',
    DRAFT: 'draft',
    DUE: 'due',
    PAID: 'paid',
    UNPAID: 'unpaid',
    PART_PAID: 'part-paid',
    REVERSAL: 'reversal',
    REPEATING: 'repeating',
}

export const REPEAT_EVERY_TYPE_WEEK = 'week'
export const REPEAT_EVERY_TYPE_MONTH = 'month'

export const REPEAT_DUE_DATE = {
    FOLLOWING_MONTH: 'following_month',
    AFTER_INVOICE_DATE: 'after_invoice_date',
    AFTER_INVOICE_DATE_END_OF_MONTH: 'after_invoice_date_end_of_month',
    CURRENT_MONTH: 'current_month',
}
