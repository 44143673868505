<template>
    <base-sticky-heading :title="$t('createInvoice.title')" class="px-6 py-3.5">
        <template #right>
            <div class="flex gap-3">
                <base-button
                    outline
                    variant="default"
                    @click="onCancel"
                    :disabled="isSaving"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    outline
                    variant="default"
                    @click="onPreview"
                    icon="line-icons:general:search-lg"
                    :loading="isSaving"
                >
                    {{ $t('createInvoice.preview') }}
                </base-button>
                <base-button
                    variant="primary"
                    icon="line-icons:general:check"
                    @click="onProceed"
                    :loading="isSaving"
                >
                    {{ $t('createInvoice.proceedBtn') }}
                </base-button>
            </div>
        </template>

        <div class="p-6">
            <create-invoice-information />
            <create-invoice-header-content :default-content="headerContent" />
            <create-invoice-line-items />
            <create-invoice-footer-content :default-content="footerContent" />
            <create-invoice-more-options />
            <create-invoice-amount />
        </div>
    </base-sticky-heading>

    <transaction-book-close-modal
        :code="code"
        :date="parameters?.date"
        @modal-close="code = null"
    />
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { INVOICE_STATUS } from '@tenant/modules/tenant/invoices/utils/constants'
import { useTextTemplate } from '@tenant/composables/apis/use-text-template'
import { dayjsFormat, shortServerFormat } from '@tenant/utils/day'
import { DATE_SERVER_FORMAT } from '@tenant/utils/constants'
const { useApiGet } = useApiFactory('sale-documents/estimates')
const { execute: executeGet } = useApiGet()
const emitter = useEmitter()
const { execute: executeGetNumber } = useApi(
    '/api/sequence-settings/generate-number/invoice',
    'GET'
)

const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const { setValues, setErrors, handleSubmit } = useForm()

useHead({
    title: t('createInvoice.title'),
})

const prefillFromEstimate = async (estimateId) => {
    emitter.emit('set-loading', true)

    const res = await executeGet(estimateId)
    const lineItems = res.line_items.map((item) => ({
        ...item.sale_document_line_item,
        tax_rate: Number(item.sale_document_line_item.tax_rate),
        discount: Number(item.sale_document_line_item.discount),
        quantity: Number(item.sale_document_line_item.quantity),
    }))

    const invoiceNumber = await executeGetNumber()

    setValues({
        contact_id: res.sale_document.contact.contact_id,
        contact: res.sale_document.contact.contact,
        address: res.sale_document.address,
        country_id: res.sale_document.country_id,
        date_of_invoice: dayjsFormat(res.date_of_invoice, DATE_SERVER_FORMAT),
        date_of_delivery: dayjsFormat(res.date_of_delivery, DATE_SERVER_FORMAT),
        due_date: dayjsFormat(res.due_date, DATE_SERVER_FORMAT),
        invoice_header: t(
            'createInvoice.invoiceHeaderPrefillPlaceholderValue',
            {
                number: invoiceNumber,
                proposalNumber: res.sale_document.number,
            }
        ),
        reference: res.sale_document.reference,
        line_items: lineItems,
    })
}

onMounted(async () => {
    try {
        if (route.query.estimate_id) {
            await prefillFromEstimate(route.query.estimate_id)
        }

        textTemplates.value = await getDefaultTextTemplate(
            'document',
            'invoices'
        )

        emitter.emit('set-loading', false)
    } finally {
        emitter.emit('set-loading', false)
    }
})

const onCancel = () => {
    router.push({ name: 'invoices.list' })
}

const onPreview = handleSubmit(async (values) => {
    const data = await saveInvoice(values)

    if (data?.id) {
        await router.push({
            name: 'invoices.dispatch',
            params: { id: data.id },
        })
    }
})

const onProceed = handleSubmit(async (values) => {
    const data = await saveInvoice(values)

    if (data?.id) {
        await router.push({
            name: 'invoices.dispatch',
            params: { id: data.id },
        })
    }
})

const store = useStore()
const currentUser = computed(() => store.getters.user)
const { useApiCreate } = useApiFactory('sale-documents/invoices')
const { execute, loading: isSaving, code, parameters } = useApiCreate()
const { defaultCurrency } = useCurrencies()

const saveInvoice = async (values, status = INVOICE_STATUS.DRAFT) => {
    try {
        return await execute({
            ...values,
            currency_id: values.currency_id ?? defaultCurrency.value.value,
            user_id: currentUser.value.id,
            status,
        })
    } catch ({ errors }) {
        setErrors(errors)
    }
}

// Get default text template for invoice
const { getDefaultTextTemplate } = useTextTemplate()
const textTemplates = ref(null)

const footerContent = computed(() => {
    return (
        textTemplates.value?.find(
            (template) => template.line_item === 'footer_content'
        )?.content ?? ''
    )
})

const headerContent = computed(() => {
    return (
        textTemplates.value?.find(
            (template) => template.line_item === 'header_content'
        )?.content ?? ''
    )
})

const contactCurrency = useFieldValue('contact_currency')
const { setValue: setExchangeRate } = useField('exchange_rate')
watchEffect(() => {
    if (!contactCurrency.value) {
        return
    }

    const { rate, rate_updated_at } = contactCurrency.value
    setExchangeRate({
        rate,
        date: shortServerFormat(rate_updated_at),
    })
})
</script>
